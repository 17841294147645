import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import { colors, space } from 'theme'

const StyledText = styled(Text)``

const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  padding: ${space.l};
  background-color: ${colors.white};
  box-shadow: 0px 4px 10px rgba(39, 40, 49, 0.03);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 0px 4px 15px rgba(39, 40, 49, 0.08);
    ${StyledText} {
      color: ${colors.primary};
    }
  }
`

const Item = ({ name, icon, link, description }) => {
  return (
    <StyledFlex as="a" href={link}>
      <LocalImage source={icon} width="48px" height="48px" />
      <StyledText mt="m" fontSize="m" fontWeight="bold" color="primaryText">
        {name}
      </StyledText>
      <Text mt="m" color="secondaryText" textAlign="center">
        {description}
      </Text>
    </StyledFlex>
  )
}

Item.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,}

export default Item
