import React from 'react'
import App from 'App'
import SEO from 'components/SEO'
import Footer from 'components/Footer'
import Container from 'components/Container'
import { Flex, Grid, Box } from 'components/Layout'
import LocalImage from 'components/LocalImage'
import Link from 'components/Link'
import { H1, Text } from 'components/Typography'
import logo from 'static/logo/logo.png'
import registrationAgencyType from 'static/images/registrationAgencyType.svg'
import registrationBusinessType from 'static/images/registrationBusinessType.svg'
import Item from './Item'

const ACCOUNT_TYPES = [
  {
    name: 'Agency',
    type: 'AGENCY',
    link: '/explainer-videos-agency',
    icon: registrationAgencyType,
    description:
      'I represent an agency looking to create an awesome performance profile and receive new opportunities.',
  },
  {
    name: 'Business',
    type: 'BUSINESS',
    link: '/explainer-videos-business',
    icon: registrationBusinessType,
    description:
      'I represent a business looking to hire a marketing agency that`s going to take my marketing to the next level.',
  },
]

const QuickStart = () => {
  return (
    <App>
      <SEO
        title="Explainer Videos"
        description="We have created several explainer videos to help our users to quickly get started with Agency Vista."
        path="/explainer-videos/"
      />
      <Container maxWidth="490px">
        <Flex mt="l" flexDirection="column" alignItems="center" textAlign="center">
          <H1>Explainer Videos</H1>
          <Text mt="l" fontSize="l" color="secondaryText">
            We have created several explainer videos to help our users to quickly get started with Agency Vista.
          </Text>
          <Text mt="l" fontSize="l" color="secondaryText">
            Please let us know who you are:
          </Text>
        </Flex>
      </Container>
      <Flex flexDirection="column" mt="m">
        <Flex justifyContent="center" mt="l">
          <Grid maxWidth="800px" gridGap="l" gridTemplateColumns={['1fr', 'repeat(2, 1fr)']}>
            {ACCOUNT_TYPES.map((item) => (
              <Item key={item.name} {...item} />
            ))}
          </Grid>
        </Flex>
      </Flex>
      <Box mt="xxl">
        <Footer />
      </Box>
    </App>
  )
}

export default QuickStart
